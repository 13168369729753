import React from 'react';

import Footer from '../components/footer/Footer';
import Navbar from '../components/Header/Navbar';

const Layout = ({ children }) => {
  return (
    <div className='relative'>
      <header className='w-full'>
        <Navbar />
      </header>
      <div class='mt-[188px]'>
        <main className=''>{children}</main>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
