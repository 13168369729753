import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PageLoader from "../components/Loader/PageLoader";
import Layout from "../layout/Layout";

const AllRoutes = () => {
  const Home = lazy(() => import("../pages/home/Home"));
  const Login = lazy(() => import("../pages/Auth/Login"));
  const Signup = lazy(() => import("../pages/Auth/Signup"));
  const ConnectWallet = lazy(() => import("../pages/connect-wallet/ConnectWallet"));
  const Onboarding = lazy(() => import("../pages/onboarding/Onboarding"));
  const Onboardingtwo = lazy(() => import("../pages/onboarding/Onboardingtwo"));
  const Onboardingthree = lazy(() => import("../pages/onboarding/Onboardingthree"));
  const Token = lazy(() => import("../pages/token/Token"));
  const NotFound = lazy(() => import("../pages/NotFound/NotFound"));
  const Document = lazy(() => import("../pages/document/Document"));
  const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
  const DashboardProfile = lazy(() =>import("../pages/DashboardProfile/DashboardProfile"));
  const DashboardWallet = lazy(() =>import("../pages/DashboardWallet/DashboardWallet"));
  const DashboardDoc = lazy(() =>import("../pages/DashboardDoc/DashboardDoc"));
  const DashboardProject = lazy(() =>import("../pages/DashboardProject/DashboardProject"));
  
  return (
    <div>
      <Toaster />
      <Suspense fallback={<PageLoader />}>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/on-boarding" element={<Onboarding />} />
              <Route path="/onboarding-two" element={<Onboardingtwo />} />
              <Route path="/onboarding-three" element={<Onboardingthree />} />
              <Route path="/connect-wallet" element={<ConnectWallet />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/token" element={<Token />} />
              <Route path="/document" element={<Document/>} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/profile" element={<DashboardProfile />} />
              <Route path='/dashboard/wallet' element={<DashboardWallet />} />
              <Route path='/dashboard/documentation' element={<DashboardDoc />} />
              <Route path='/dashboard/projects' element={<DashboardProject />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default AllRoutes;
