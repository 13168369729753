import React from 'react';
import { Link } from 'react-router-dom';

import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

import HorizontalDivider from '../Divider/HorizontalDivider';
import Dapi from '../../assets/images/dapilogo.png';

const Footer = () => {
  return (
    <div className='px-8 md:px-20 mb-12'>
      <HorizontalDivider className='my-12' />
      <div className='flex flex-col gap-10 tab:flex-row tab:justify-between tab:gap-40'>
        <Link className='flex items-start gap-2' to={'/'}>
          <div className='w-12 h-12 md:w-[62px] md:h-[62px]'>
            <img src={Dapi} alt='dapi logo' />
          </div>
          <span className='tab:text-[2rem] font-bold'>DAPI</span>
        </Link>
        <div className='flex flex-col gap-8 sm:flex-row sm:gap-y-10 flex-wrap lg:gap-14 xl:gap-20'>
          <div>
            <h3 className='text-primary mb-4'>Services</h3>
            <ul className='text-sm flex flex-col gap-3'>
              <li>
                <Link to='#'>Smart Contract Development</Link>
              </li>
              <li>
                <Link to='#'>Blockchain Integration</Link>
              </li>
              <li>
                <Link to='#'>Deployment and Hosting</Link>
              </li>
              <li>
                <Link to='#'>Testing and Debugging</Link>
              </li>
              <li>
                <Link to='#'>E-commerce Registration</Link>
              </li>
              <li>
                <Link to='#'>Security and Auditing</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className='text-primary mb-4'>About us</h3>
            <ul className='text-sm flex flex-col gap-3'>
              <li>
                <Link to='#'>About us</Link>
              </li>
              <li>
                <Link to='#'>Collaboration</Link>
              </li>
              <li>
                <Link to='#'>Development</Link>
              </li>
              <li>
                <Link to='#'>E-commerce</Link>
              </li>
              <li>
                <Link to='#'>Monitoring</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className='text-primary mb-4'>Help and Support</h3>
            <ul className='text-sm flex flex-col gap-3'>
              <li>
                <Link to='#'>Help center</Link>
              </li>
              <li>
                <Link to='#'>Privacy & Terms</Link>
              </li>
              <li>
                <Link to='#'>Support</Link>
              </li>
              <li>
                <Link to='#'>Contact us</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className='text-primary mb-4'>Community</h3>
            <ul className='text-sm flex flex-col gap-3'>
              <li>
                <Link to='#'>Developers</Link>
              </li>
              <li>
                <Link to='#'>Discussion channels</Link>
              </li>
              <li>
                <Link to='#'>Events</Link>
              </li>
              <li>
                <Link to='#'>Open-source</Link>
              </li>
              <li>
                <Link to='#'>Workshop</Link>
              </li>
              <li>
                <Link to='#'>Partnership</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <HorizontalDivider className={'mb-10 mt-12'} />
      <div className='flex flex-col gap-10 sm:flex-row justify-between items-center'>
        <div className='flex gap-[54px]'>
          <div>
            <p className='text-sm text-primary'>copyright 2023</p>
          </div>
          <ul className='flex gap-4'>
            <li className='text-sm text-primary'>
              <Link to='#'>Help</Link>
            </li>
            <li className='text-sm text-primary'>
              <Link to='#'>Privacy</Link>
            </li>
            <li className='text-sm text-primary'>
              <Link to='#'>Terms</Link>
            </li>
          </ul>
        </div>
        <div className='flex gap-4'>
          <FaFacebook />
          <FaTwitter />
          <FaInstagram />
        </div>
      </div>
    </div>
  );
};

export default Footer;
