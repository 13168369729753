import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Dapi from '../../assets/dapilogo.png';
import { Link, NavLink } from 'react-router-dom';
import Button from '../Button/Button';
import Menu from '../../assets/icons/menu.svg';

const Navbar = ({children}) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleMobileMenu = () => {
    setShowMobileNav((o) => !o);
  };
  return (
    <div className='relative'>
      <div className='fixed top-0 right-0 left-0 bg-gradient z-50'>
        <div className='flex justify-between items-center px-8 md:px-20 py-8 lg:gap-14'>
          <Link className='flex items-center gap-2' to={'/'}>
            <span className='w-12 h-12 md:w-[62px] md:h-[62px]'>
              <img src={Dapi} alt='dapi logo' />
            </span>
            <span className='tab:text-[2rem] font-bold'>DAPI</span>
          </Link>
         
          <div className='hidden tab:flex flex-col items-center gap-4 justify-center'>
            <div>
              
            </div>
            <nav>
              <ul className='flex items-center tab:gap-8 xl:gap-10'>
                <li>
                  <NavLink href='#timeline' className='cursor-pointer'>
                    Solutions
                  </NavLink>
                </li>
                
                <li>
                  <NavLink href='#overview' className='cursor-pointer'>
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink href='#faqs' className='cursor-pointer'>
                    Resources
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/contact' className='cursor-pointer'>
                    Company
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/document' className='cursor-pointer'>
                    Doc
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className='hidden tab:block whitespace-nowrap'>
            <Button>
              <Link to='/register' className='cursor-pointer'>
                Connect wallet
              </Link>
            </Button>
          </div>

          {/* Mobile Hamburger Menu */}
          <div className='tab:hidden'>
            <button onClick={handleMobileMenu}>
              <img src={Menu} alt='menu-icon' />
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {showMobileNav && (
        <div className='py-9 px-11 tab:hidden fixed bg-[#150E28] w-full min-h-screen top-0 z-50'>
          <div
            className='flex justify-end mb-14'
            onClick={() => setShowMobileNav(false)}
          >
            <AiOutlineClose
              size={23}
              className='border rounded-full border-purple-50 px-1'
            />
          </div>
          <nav>
            <ul className='flex flex-col gap-6 sm:gap-8 text-lg'>
              <li>
                <NavLink
                  href='#timeline'
                  onClick={handleMobileMenu}
                  className='cursor-pointer'
                >
                  Solutions
                </NavLink>
              </li>
              <li>
                <NavLink
                  href='#overview'
                  onClick={handleMobileMenu}
                  className='cursor-pointer'
                >
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink
                  href='#faqs'
                  onClick={handleMobileMenu}
                  className='cursor-pointer'
                >
                  Resources
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/contact'
                  onClick={handleMobileMenu}
                  className='cursor-pointer'
                >
                  Company
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/contact'
                  onClick={handleMobileMenu}
                  className='cursor-pointer'
                >
                  Doc
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className='mt-7'>
            <Button>
              <Link to='/register' className='cursor-pointer'>
                Connect Wallet
              </Link>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
