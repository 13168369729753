import React from 'react';

const HorizontalDivider = ({ className }) => {
  return (
    <div>
      <hr className={`${className && className} h-[1px] bg-[#6A6A6A]`} />
    </div>
  );
};

export default HorizontalDivider;
